











































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import CustomerIssueDialog from '../components/CustomerIssueDialog.vue'
import Alert from '@/components/Alert.vue'
import ICustomerIssue from '../models/ICustomerIssue'
import CustomerSearchPanel from '../components/CustomerSearchPanel.vue'
import ISearchModel from '../models/ISearchModel'
import { RawLocation } from 'vue-router'

const { mapGetters, mapActions } = createNamespacedHelpers('Customers')

export default Vue.extend({
  components: {
    CustomerSearchPanel,
    CustomerIssueDialog,
    Alert
  },
  data() {
    return {
      loadFavorites: false,
      customerIssue: null as ICustomerIssue,
      searchModel: {} as ISearchModel,
      loading: false,
      searchError: null,
      loadingText: 'Searching...'
    }
  },
  computed: {
    ...mapGetters(['hasAccess', 'searchResults', 'error'])
  },
  methods: {
    ...mapActions({
      fileIssue: 'fileIssue',
      search: 'search',
      byTpid: 'byTpid',
      export: 'export',
      clearSearch: 'clearSearch'
    }),
    async onSubmitCustomerIssue() {
      await this.fileIssue(this.customerIssue)
      this.customerIssue = null
    },
    async onSearch(val) {
      this.loadingText = 'Searching...'
      this.searchModel = val
      this.loading = true
      try {
        try {
          await this.search(this.searchModel)
          this.updateUrl()
        } catch (e) {
          this.searchError = e
        }
      } finally {
        this.loading = false
      }
    },
    async onExport() {
      this.loadingText = 'Exporting...'
      this.loading = true
      try {
        await this.export(this.searchModel)
      } finally {
        this.loading = false
      }
    },
    onBack() {
      this.searchModel = null
      this.clearSearch()
      this.updateUrl()
    },
    updateUrl() {
      const newRoute: RawLocation = {
        name: 'CustomerDetails',
        query: {}
      }

      if (this.searchModel) {
        newRoute.query.searchText = this.searchModel.searchText || undefined
        newRoute.query.customerId = this.searchModel.customerId === 0 ? undefined : this.searchModel.customerId.toString() || undefined
        newRoute.query.favorites = this.searchModel.favorites === false ? undefined : this.searchModel.favorites.toString()
        newRoute.query.directAssignments = this.searchModel.directAssignments === false ? undefined : this.searchModel.directAssignments.toString()
        newRoute.query.isOrganization = this.searchModel.$_isOrganization === false ? undefined : this.searchModel.$_isOrganization.toString()
        newRoute.query.id = this.searchModel.$_isOrganization ? this.searchModel.$_id === 0 ? undefined : this.searchModel.$_id.toString() : undefined
      }
      this.$router.push(newRoute)
    },
    async load() {
      if (this.searchResults === null) {
        const customerId = +this.$route.query.customerId
        const favorites = this.$route.query.favorites === 'true'
        const directAssignments = this.$route.query.directAssignments === 'true'
        const searchText = this.$route.query.searchText
        const tpid = +this.$route.query.tpid
        const $_isOrganization = this.$route.query.isOrganization === 'true'
        const $_id = +this.$route.query.id

        if (favorites) {
          this.onSearch({
            favorites,
            customerId: 0,
            searchText: '',
            directAssignments: false,
            $_isOrganization,
            $_id
          })
        } else if (directAssignments) {
          this.onSearch({
            favorites: false,
            customerId: 0,
            searchText: '',
            directAssignments,
            $_isOrganization,
            $_id
          })
        } else if (!isNaN(customerId)) {
          this.onSearch({
            favorites: false,
            customerId,
            searchText: '',
            directAssignments: false,
            $_isOrganization,
            $_id
          })
        } else if (searchText) {
          this.onSearch({
            favorites: false,
            customerId: 0,
            searchText,
            directAssignments: false,
            $_isOrganization,
            $_id
          })
        } else if (tpid) {
          try {
            const response = await this.byTpid(tpid)
            this.onSearch({
              favorites: false,
              customerId: response.id,
              searchText: '',
              directAssignments: false,
              $_isOrganization,
              $_id
            })
          } catch (e) {
            this.searchError = e
          }
        } else {
          this.clearSearch()
        }
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.load()
      }
    }
  },
  mounted() {
    this.load()
  }
})
