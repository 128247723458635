











































































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import CustomerProductTable from './CustomerProductTable.vue'
import ITimeFrameSearchResultModel from '../models/ITimeFrameSearchResultModel'
import IProductSearchResultModel from '../models/IProductSearchResultModel'
import { orderBy, uniqBy } from 'lodash'
import IGraphQueryProduct from '@/areas/queries/models/IGraphQueryProduct'

const { mapActions, mapGetters } = createNamespacedHelpers('Customers')

export default Vue.extend({
  props: {
    value: Object,
    searchModel: Object
  },
  components: {
    CustomerProductTable
  },
  data() {
    return {
      customers: [],
      open: [],
      timeFrames: [] as ITimeFrameSearchResultModel[],
      products: [] as IProductSearchResultModel[],
      mySelectedTimeFrames: []
    }
  },
  computed: {
    ...mapGetters([
      'selectedTimeFrames',
      'selectedProducts',
      'selectedCustomers',
      'customersToShow',
      'productsToShow',
      'licenses']),
    canCreateReport() : boolean {
      return !(this.selectedProducts.length > 0 && this.selectedProducts.some(x => this.productsToShow.findIndex(y => y.listingId === x.listingId) > -1))
    },
    canViewTimeline() : boolean {
      const tpids = [...new Set(this.customers.filter(x => x.children.filter(y => this.selectedCustomers.indexOf(y.key) > -1).length > 0).map(x => x.tpid))]
      return this.customers.length === 1 || tpids.length === 1
    }
  },
  methods: {
    ...mapActions({
      toggleCustomerFavorite: 'toggleFavorite',
      setSelectedTimeFrames: 'setSelectedTimeFrames',
      setSelectedCustomers: 'setSelectedCustomers',
      setSelectedProducts: 'setSelectedProducts',
      setCustomersToShow: 'setCustomersToShow',
      setProductsToShow: 'setProductsToShow',
      setLicenses: 'setLicenses'
    }),
    getCPEProfilesUrl(item) {
      return `https://aka.ms/cpeprofile?filter=CALCAccountParenting/TPID%20eq%20%27${item.tpid}%27`
    },
    disableTimeFrame(timeFrame: ITimeFrameSearchResultModel) : boolean {
      if (this.selectedCustomers.length === 0) {
        return timeFrame.products.length === 0
      } else {
        const customers = this.customers.flatMap(x => x.children.filter(y => this.selectedCustomers.indexOf(y.key) > -1))
        const customerProducts = customers.flatMap(x => x.products.map(y => y.listingId))
        return timeFrame.products.every(x => customerProducts.indexOf(x) === -1)
      }
    },
    onTimeframeSelectedChange() {
      this.setSelectedTimeFrames(this.mySelectedTimeFrames)
        .then(() => this.showLicenses())
    },
    onExport() {
      this.$emit('export')
    },
    getCustomersTitle() : string {
      let title = this.customers.length > 1 ? 'Customers' : 'Customer'
      if (this.selectedTimeFrames.length > 0) {
        title = `${title} (Filtered)`
      }
      return title
    },
    getProductsTitle() : string {
      let title = 'Products'
      if (this.selectedTimeFrames.length > 0 || this.selectedCustomers.length > 0) {
        title = `${title} (Filtered)`
      }
      return title + ' - Check product boxes to see license info'
    },
    getEosIcon(timeFrame: ITimeFrameSearchResultModel) : string {
      if (timeFrame.label === '0 to 6 Months') {
        return '$square'
      } else if (timeFrame.label === '7 to 12 Months') {
        return '$triangle'
      } else if (timeFrame.label === '13 to 24 Months') {
        return '$circle'
      } else if (timeFrame.label === 'Out of Support') {
        return '$stop'
      }
      return ''
    },
    getEosIconColor(timeFrame: ITimeFrameSearchResultModel) : string {
      if (timeFrame.label === '0 to 6 Months') {
        return 'red'
      } else if (timeFrame.label === '7 to 12 Months') {
        return 'orange'
      } else if (timeFrame.label === '13 to 24 Months') {
        return 'gray'
      } else if (timeFrame.label === 'Out of Support') {
        return 'red'
      }
      return ''
    },
    toggleFavorite(item: any) {
      item.favorite = !item.favorite
      this.toggleCustomerFavorite(item)
    },
    showLicenses() {
      const licenses = []
      if (this.selectedCustomers.length > 0) {
        this.selectedCustomers.forEach(x => {
          this.customers.forEach(y => {
            y.children.forEach(z => {
              if (z.key === x) {
                z.products.filter(p => {
                  licenses.push({
                    id: z.id,
                    customerName: y.name,
                    organizationName: z.name,
                    productId: p.listingId,
                    licenseCount: p.licenseCount
                  })
                })
              }
            })
          })
        })
      } else {
        this.customersToShow.forEach(y => {
          y.children.forEach(z => {
            z.products.filter(p => {
              licenses.push({
                id: z.id,
                customerName: y.name,
                organizationName: z.name,
                productId: p.listingId,
                licenseCount: p.licenseCount
              })
            })
          })
        })
      }
      const uniqueLicenses = uniqBy(licenses, (x) => {
        return {
          id: x.id,
          productId: x.productId
        }
      })
      this.setLicenses(uniqueLicenses)
    },
    onProductSelected(val: IProductSearchResultModel) {
      if (val.$_selected) {
        this.selectedProducts.push(val)
      } else {
        const index = this.selectedProducts.indexOf(val)
        if (index > -1) {
          this.selectedProducts.splice(index, 1)
        }
      }
      this.showLicenses()
    },
    onTimeframeSelected() {
      this.filterCustomers()
      this.filterProducts()
    },
    onCustomerSelectionChanged(input) {
      console.log(input)
      this.setSelectedCustomers(input)
        .then(() => {
          this.filterProducts()
          this.showLicenses()
        })
    },
    filterProducts() {
      const products = []
      if (this.selectedTimeFrames.length > 0) {
        this.selectedTimeFrames.forEach(x => {
          products.push(...this.products.filter(y => x.products.indexOf(y.listingId) > -1))
        })
      } else {
        products.push(...this.products)
      }
      const customerProducts = []
      if (this.selectedCustomers.length > 0) {
        const selectedCustomersShowing = this.customersToShow.flatMap(x => x.children).filter(x => this.selectedCustomers.indexOf(x.key) > -1)
        selectedCustomersShowing.forEach(x => {
          customerProducts.push(...x.products)
        })
      } else {
        if (this.customersToShow.length > 0) {
          this.customersToShow.flatMap(x => x.children).forEach(x => {
            customerProducts.push(...x.products)
          })
        } else {
          this.customers.flatMap(x => x.children).forEach(x => {
            customerProducts.push(...x.products)
          })
        }
      }
      this.setProductsToShow(products.filter(x => {
        return customerProducts.map(y => y.listingId).indexOf(x.listingId) > -1
      }))
      this.products.forEach(x => {
        x.$_hide = this.productsToShow.indexOf(x) === -1
      })
    },
    filterCustomers() {
      let products = this.products.map(x => x.listingId)
      if (this.selectedTimeFrames.length > 0) {
        products = this.selectedTimeFrames.flatMap(x => x.products)
      }
      const showCustomers = []
      this.customers.forEach(x => {
        x.children.forEach(y => {
          y.disabled = !y.products.map(z => z.listingId).some(z => products.indexOf(z) > -1)
        })
        x.disabled = x.children.every(y => y.disabled)
        if (!x.disabled) {
          const copy = {
            ...x
          }
          copy.children = copy.children.filter(y => !y.disabled)
          showCustomers.push(copy)
        }
      })
      this.setCustomersToShow(showCustomers)
    },
    createReport() {
      const products = this.selectedProducts.filter(x => this.productsToShow.findIndex(y => y.listingId === x.listingId) > -1)
      if (products) {
        let reportName = ''
        if (this.customers.length === 1) {
          reportName = this.customers[0].name
        } else if (this.selectedCustomers.length === 1) {
          reportName = this.selectedCustomers[0].name
        }
        const graphQueryProducts = products.map(x => {
          return {
            $_productName: x.listingName,
            listingId: x.listingId,
            includeInReport: true
          } as IGraphQueryProduct
        }) as any
        const selectAllReleases = true as any
        this.$router.push({
          name: 'ReportBuilder',
          params: {
            selectAllReleases,
            graphQueryProducts,
            reportName
          }
        })
      }
    },
    viewTimeline() {
      let tpids = [this.customers[0].tpid]
      if (this.customers.length > 1) {
        tpids = [...new Set(this.customers.filter(x => x.children.filter(y => this.selectedCustomers.indexOf(y.key) > -1).length > 0).map(x => x.tpid))]
      }
      const orgIds = []
      this.customers.forEach(x => {
        x.children.forEach(y => {
          if (this.selectedCustomers.indexOf(y.key) > -1) {
            orgIds.push(y.organizationId)
          }
        })
      })
      this.$router.push({
        name: 'CustomerTimeline',
        params: {
          tpid: tpids[0],
          orgIds: orgIds.toString()
        }
      })
    },
    backToSearch() {
      this.$emit('back')
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.timeFrames = newValue.timeFrames
          this.mySelectedTimeFrames = this.selectedTimeFrames
          this.products = newValue.products
          this.setProductsToShow(this.products.filter(x => !x.$_hide))
          this.customers = orderBy(newValue.customers.map(x => {
            const organizations = orderBy(newValue.organizations.filter(y => y.customerId === x.id).map(y => {
              return {
                key: `ORG-${y.id}`,
                id: y.id,
                organizationId: y.organizationId,
                label: y.name,
                name: y.name,
                lowerName: y.name.toLowerCase(),
                disabled: false,
                products: y.products
              }
            }), 'lowerName')
            return {
              key: `CUS-${x.id}`,
              id: x.id,
              label: `${x.tpid} - ${x.name}`,
              name: x.name,
              tpid: x.tpid,
              lowerName: x.name.toLowerCase(),
              favorite: x.favorite,
              children: organizations,
              disabled: false,
              products: [...new Set(organizations.flatMap(y => y.products))]
            }
          }), 'tpid')
          this.setCustomersToShow(this.customers)
          if (this.customers.length === 1) {
            this.open = [this.customers[0].key]
          }
          this.showLicenses()
          if (this.searchModel && this.searchModel.$_isOrganization) {
            const org = this.customers.flatMap(x => x.children).filter(x => x.organizationId === this.searchModel.$_id)
            if (org) {
              this.setSelectedCustomers([org[0].key])
              this.onCustomerSelectionChanged(this.selectedCustomers)
            }
          } else {
            this.setSelectedCustomers([])
            this.onCustomerSelectionChanged(this.selectedCustomers)
          }
        }
      }
    },
    mySelectedTimeFrames() {
      this.onTimeframeSelected()
    }
  }
})
